import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, Theme, ThemeProvider } from "@mui/material/styles";
import { useEffect, useState } from "react";
import BookingApi from "../../../API/Apis/BookingApi";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import NotAuthorisedPage from "../../../views/notAuthorisedPage";
import { BookingGetters } from "../../../store/onlineBooking/getters";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";

import { t } from "i18next";
import { AppStandardButton } from "../../UI/AppButtons";
import theme, { formatTheme } from "../../../config/MuiColorThemes";

export default function SignIn() {
  const navigate = useNavigate();
  const dynamicPalette = formatTheme();
  const dynamicTheme = createTheme(dynamicPalette);
  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email is required").email("email is invalid"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters"),
  });
  const handleSignupNavigation = (e) => {
    e.preventDefault();
    navigate("/signup");
  };

  const handleForgotPasswordNavigation = (e) => {
    e.preventDefault();
    navigate("/forgotpassword");
  };
  const isDesktopScreen = window.innerWidth >= 1310;
  const isSmallScreen = window.innerWidth <= 768;
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    if (data.remember) {
      localStorage.setItem("email", data.email);
      localStorage.setItem("password", data.password);
    } else {
      localStorage.removeItem("email");
      localStorage.removeItem("password");
    }
    BookingApi.loginUser(data).then((data) => {
      if (data.data.status == "success") {
        localStorage.setItem("userID", data.data.user.id);
        localStorage.setItem("isUserLoggedin", "true");
        localStorage.setItem("userAffiliate", data?.data?.user?.affiliate);
        navigate("/");
        localStorage.setItem(
          "onlineUserType",
          data?.data?.onlineUserType?.description
        );
      }
      if (data.data.status != "success") {
        console.log(data.data);
        setModalMessage(data.data.msg || "Password does not match");
        setOpenModal(true);
      }
    });
  };
  const appSettings = BookingGetters.getAppSettings();
  const [showReserve, setShowReserve] = useState<boolean>(
    !!appSettings?.reservedArea
  );
  useEffect(() => {
    BookingApi.getAppSetting().then((e: any) => {
      setShowReserve(e.reservedArea);
    });
  }, [showReserve]);
  const [openModal, setOpenModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const requestOnClose = () => {
    setOpenModal(false);
    setModalMessage("");
  };

  return (
    <ThemeProvider theme={dynamicTheme}>
      <div className="grid grid-cols-1 h-[100vh] md:grid-cols-2">
        <div className="">
          <img
            src="/assets/images/reserve_area_car.jpg"
            className="w-full h-full object-cover"
          />
        </div>
        <div
          className="flex flex-col items-center w-full"
          style={{
            width: isDesktopScreen ? "691px" : "",
            height: isDesktopScreen ? "600px" : "500px",
          }}
        >
          <div className="w-[100%] flex-col items-center justify-center">
            <h1
              className="text-3xl"
              style={{
                color: dynamicTheme?.palette["secondaryColor"]?.main,
                marginTop: isDesktopScreen ? "160px" : "60px",
                marginLeft: isDesktopScreen ? "139px" : "50px",
              }}
            >
              Sign in to your account
            </h1>
            <Container component="main">
              <Grid container justifyContent="center">
                <Box
                  component="form"
                  onSubmit={handleSubmit(onSubmit)}
                  noValidate
                  sx={{
                    "& .MuiTextField-root": {
                      mt: 2,
                      width: isDesktopScreen ? "40ch" : "100%",
                      backgroundColor: "white",
                    },
                  }}
                >
                  <Grid item xs={12}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label={t("Email_Address")}
                      size="medium"
                      autoComplete="off"
                      /* style={{
                        width: isDesktopScreen ? "418px" : "100%",
                        marginLeft: isSmallScreen ? "5px" : "",
                        backgroundColor: "#D9D9D9",
                        color: "black",
                        borderRadius: "8px",
                      }}*/
                      autoFocus
                      {...register("email")}
                      error={!!errors.email}
                      /*InputLabelProps={{
                        sx: {
                          "&.MuiInputLabel-shrink": {
                            display: "none",
                          },
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "transparent",
                          },
                          "&:hover fieldset": {
                            borderColor: "transparent",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "transparent",
                          },
                        },
                      }}*/
                    />
                    <Typography variant="inherit">
                      {errors.email?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      /* style={{
                        width: isDesktopScreen ? "418px" : "100%",
                        marginLeft: isSmallScreen ? "5px" : "",
                        backgroundColor: "#D9D9D9",
                        color: "black",
                        borderRadius: "8px",
                      }}*/
                      label={t("Password")}
                      type="password"
                      id="password"
                      size="medium"
                      autoComplete="off"
                      {...register("password")}
                      error={!!errors.password}
                      /*     InputLabelProps={{
                        sx: {
                          "&.MuiInputLabel-shrink": {
                            display: "none",
                          },
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "transparent",
                          },
                          "&:hover fieldset": {
                            borderColor: "transparent",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "transparent",
                          },
                        },
                      }}*/
                    />
                    <Typography variant="inherit">
                      {errors.password?.message}
                    </Typography>
                  </Grid>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{
                      mt: 3,
                      mb: 2,
                      fontWeight: "bold",
                      width: isDesktopScreen ? "350px" : "100%",
                    }}
                  >
                    Login
                  </Button>
                  <Grid container style={{ padding: "16px" }}>
                    <Grid item xs={12} md={6}>
                      <Link
                        onClick={handleForgotPasswordNavigation}
                        variant="body2"
                        component="button"
                        underline="none"
                      >
                        <span
                          className="whitespace-nowrap"
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          {t("Forgot_password")} ?
                        </span>
                      </Link>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Link
                        onClick={handleSignupNavigation}
                        variant="body2"
                        component="button"
                        underline="none"
                      >
                        <span
                          className="whitespace-pre-line"
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          Are you not registered yet? Signup
                        </span>
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Container>
          </div>
        </div>
        <Dialog
          open={openModal}
          onClose={requestOnClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{ style: { borderRadius: "20px" } }}
        >
          <DialogTitle style={{ padding: "3rem" }}>{modalMessage}</DialogTitle>

          <DialogActions>
            <AppStandardButton variant="outlined" onClick={requestOnClose}>
              <p className="text-black normal-case">Close</p>
            </AppStandardButton>
          </DialogActions>
        </Dialog>
      </div>
    </ThemeProvider>
  );
}
