import {
  createSearchParams,
  NavigateFunction,
  useNavigate,
} from "react-router-dom";

import { BOOKING_ACTIONS } from "../../store/onlineBooking";
import BookingForm from "../../components/Layouts/BookingForm/BookingForm";
import { PageConfigGetters } from "../../store/PageConfigurations/getters";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import BookingApi from "../../API/Apis/BookingApi";
import NotAuthorisedPage from "../notAuthorisedPage";
import CollapsibleNavbar from "../../components/Layouts/ReservedAreaDashboard/CollapseNavbar";
import { formatTheme } from "../../config/MuiColorThemes";
import { createTheme, ThemeProvider } from "@mui/material/styles";

function HomePage(): JSX.Element {
  const dynamicPalette = formatTheme();
  const dynamicTheme = createTheme(dynamicPalette);
  const BookingFormConfig = PageConfigGetters.getBookingFormConfig();
  const dispatch = useDispatch();
  const routerNavigation: NavigateFunction = useNavigate();
  useEffect(() => {
    dispatch(BOOKING_ACTIONS.setSessionExpiryTime(0));
  }, []);
  const [showBooking, setShowBooking] = useState<boolean>(true);
  const [isEnableHorizontalView, setIsEnableHorizontalView] = useState<
    boolean | null
  >(null);
  useEffect(() => {
    BookingApi.getAppSetting().then((e: any) => {
      setIsEnableHorizontalView(e.enableHorizontalView);
      setShowBooking(e.booking);
    });
  }, [showBooking]);
  const [userId] = useState(localStorage.getItem("userID"));
  const [reservedArea, setReservedArea] = useState(false);
  useEffect(() => {
    if (userId != null && userId != "null" && userId != "") {
      setReservedArea(true);
      BookingApi.getUserProfileByUserId(parseInt(userId)).then((e) => {
        if (e.data.status == "Not Found") {
          setReservedArea(false);
        } else {
          setReservedArea(true);
        }
      });
    } else {
      setReservedArea(false);
    }
  }, [userId]);

  return (
    <ThemeProvider theme={dynamicTheme}>
      <div className="min-h-screen flex flex-col">
        <section className="h-full  box-border flex flex-col md:px-12 px-4 ">
          {showBooking ? (
            <div className="flex">
              <div className="w-[100%] rounded-sm pt-12 pb-12 h-[100%]">
                {BookingFormConfig.fields && (
                  <>
                    {reservedArea && <CollapsibleNavbar callFrom="dashboard" />}
                    {isEnableHorizontalView !== null ? (
                      <BookingForm
                        className="grow"
                        isEnableHorizontalView={isEnableHorizontalView}
                        options={BookingFormConfig.fields || []}
                        onSubmit={(e) => {
                          dispatch(
                            BOOKING_ACTIONS.setBookingInformation({
                              ...e,
                            })
                          );

                          const searchParams = createSearchParams({
                            dropOffDateAndTime: e.dropOffDateAndTime,
                            pickUpDateAndTime: e.pickUpDateAndTime,
                            pickUpLocation: e.pickUpLocationDetails.code,
                            dropOffLocation: e.dropOffLocationDetails.code,
                            pickUpLocationName: e.pickUpLocationDetails.name,
                            dropOffLocationName: e.dropOffLocationDetails.name,
                            reservationSource: e.reservationSource.code,
                            macroGroupName: e.macroGroupName,
                            agreementCoupon: e.agreementCoupon,
                            showPics: "true",
                            showOptionalImage: "true",
                            showVehicleParameter: "true",
                            showVehicleExtraImage: "true",
                            showBookingDiscount: "true",
                            isYoungDriverAge: e.isYoungDriverAge,
                            isYoungDriver2Age: e.isYoungDriver2Age,
                            isSeniorDriverAge: e.isSeniorDriverAge,
                            noFeeAge: e.noFeeAge,
                            isAgeLimitSelected: e.isAgeLimitSelected,
                            reservationSourcePayNow:
                              e.reservationSource.reservationSourcePayNow,
                            reservationSourcePayLater:
                              e.reservationSource.reservationSourcePayLater,
                            youngDriverAgeValue: e.youngDriverAgeValue,
                            youngDriver2AgeValue: e.youngDriver2AgeValue,
                            seniorDriverAgeValue: e.seniorDriverAgeValue,
                            noAgeDriverValue: e.noAgeDriverValue,
                            affiliateName:
                              e.affiliateDetails.name != ""
                                ? e.affiliateDetails.name
                                : null,
                            affiliateId:
                              e.affiliateDetails.code != ""
                                ? e.affiliateDetails.code
                                : null,
                            isAirport: e.pickUpLocationDetails.isAirport,
                          }).toString();
                          routerNavigation("/choose/vehicle?" + searchParams);
                        }}
                      />
                    ) : (
                      <div>Loading...</div>
                    )}
                  </>
                )}
              </div>
            </div>
          ) : (
            <NotAuthorisedPage />
          )}
        </section>
      </div>
    </ThemeProvider>
  );
}
export default HomePage;
